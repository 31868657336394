<div class="field d-flex flex-row pb-3 align-items-end">
    <div class="field-heading flex-grow-1">{{title}}</div>
</div>

<table class="table table-striped table-responsive table-bordered" *ngIf="lotteryEnrollments.length">
    <thead style="font-weight: bold;">
        <tr>
            <td>{{'Title' | transloco}}</td>
            <td>{{'Sport' | transloco}}</td>
            <td>{{'EnrollmentStart' | transloco}}</td>
            <td>{{'Date' | transloco}}</td>
            <td>{{'Place' | transloco}}</td>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let enrollment of lotteryEnrollments">
            <td><a [routerLink]="'/lessons/'+enrollment.lessonId">{{enrollment.lessonName}}</a></td>
            <td>{{enrollment.sportName}}</td>
            <td>{{enrollment.lessonEnrollmentFrom | fromToDate: null : 'dd, DD.MM.YYYY HH:mm': true}}</td>
            <td>{{enrollment.lessonStart | fromToDate: enrollment.lessonEnd : 'DD.MM.YYYY': false}}</td>
            <td>
                <span *ngIf="enrollment.location">{{enrollment.location}}</span>
                <span *ngIf="!enrollment.location && enrollment.room">{{enrollment.room}}</span>
            </td>
        </tr>
    </tbody>
</table>

<p class="well" *ngIf="!lotteryEnrollments.length">{{'NoEntries' | transloco}}</p>