import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { RegistrationStates } from 'src/app/events/registration/registration-states.enum';
import { environment } from 'src/environments/environment';
import { RegistrationList } from '../models/registration-list.model';

@Component({
  selector: 'app-registrations-table',
  templateUrl: './registrations-table.component.html',
  styleUrls: ['./registrations-table.component.scss']
})
export class RegistrationsTableComponent implements OnChanges, OnInit {
  @Input() title!: any;
  @Input() registrations!: RegistrationList[];
  @Input() showToggle = false;

  showPastRegistrations: boolean = false; 

  externalSiteUrl: string;

  registrationStateCompleted = RegistrationStates.Completed;

  filteredRegistrations: RegistrationList[] = []; 

  constructor() {
    this.externalSiteUrl = environment.externalSite;
  }

  ngOnInit(): void {
    if(this.showToggle){
      this.applyFilter();
    }else{
      this.filteredRegistrations = this.registrations;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['registrations'] != null) {
      this.registrations = this.sortEntriesDescByDate(this.registrations);
    }
  }

  onToggleChange(value: boolean) {
    this.applyFilter();
  }

  applyFilter() {
    const now = new Date();

    if (this.showPastRegistrations) {
      this.filteredRegistrations = [...this.registrations];
    } else {
      this.filteredRegistrations = this.registrations.filter(registration => {
        const eventStart = this.parseToDate(registration.eventStart);
        return eventStart && eventStart >= now;
      });
    }
  }

  parseToDate(date: any): Date | null {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date; // It's already a valid Date object
    } else if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate; // Successfully parsed string to Date
      }
    }
    return null; // Invalid date
  }
  
  sortEntriesDescByDate(registrations: RegistrationList[]): RegistrationList[] {
    return registrations.sort((a, b) => {
      const dateA = this.parseToDate(a?.eventStart);
      const dateB = this.parseToDate(b?.eventStart);
    
      if (dateA === null && dateB === null) return 0; // Both invalid, keep original order
      if (dateA === null) return 1; // Only A is invalid, B comes first
      if (dateB === null) return -1; // Only B is invalid, A comes first
    
      return dateB.getTime() - dateA.getTime(); // Both are valid, compare as usual
    });
  }


  getRegistrationRoute(id: number, state: number): string {
    if (state === RegistrationStates.Completed
      || state === RegistrationStates.Expired
      || state === RegistrationStates.Error) {
      return `/registration/${id}/details`;
    }
    return `/registration/${id}/wizard`;
  }

  getCorrespondingIcon(value: RegistrationStates): string | undefined {
    switch (value) {
      case RegistrationStates.Completed:
        return 'fa fa-circle-check';
      case RegistrationStates.Canceled:
      case RegistrationStates.EventoCanceled: // Evento Canceled
        return 'fa fa-circle-xmark';
      case RegistrationStates.Temporary || RegistrationStates.New:
        return 'fa fa-file';
      case RegistrationStates.Expired:
        return 'fa fa-clock';
      case RegistrationStates.Error:
        return 'fa fa-bolt';
      case RegistrationStates.ProcessPayment || RegistrationStates.AdditionalPayment:
        return 'fa fa-dollar-sign';
        default:
          return undefined;
    }
  }
}

