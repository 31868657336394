<!-- Badges -->
<div class="d-flex gap-1 flex-wrap">
    <span class="badge badge-blue">{{'EventLessonBadges.Type.Lesson' | transloco}}</span>

    <ng-container *ngIf="enrollmentProperties != null">

        <!-- General information badges -->
        <span class="badge badge-blue" *ngIf="enrollmentProperties.lessonIsLivestream">
            {{'EventLessonBadges.Type.Livestream' | transloco}}
        </span>

        <!-- User specific badges -->
        <span class="badge badge-green" *ngIf="enrollmentProperties.userIsEnrolled">
            {{'EventLessonBadges.UserSpecific.Enrolled' | transloco}}
        </span>
        <span class="badge badge-green" *ngIf="enrollmentProperties.userIsEnrolledToLottery">
            {{'EventLessonBadges.UserSpecific.LotteryEnrolled' | transloco}}
        </span>
        <span class="badge badge-red" *ngIf="enrollmentProperties.userHasMissingSkill">
            {{'EventLessonBadges.UserSpecific.SkillsNotFulfilled' | transloco}}
        </span>
        <span class="badge badge-blue"
            *ngIf="enrollmentProperties.userIsEnrolled && enrollmentProperties.currentEnrollment?.placeNumber != null">
            {{'EventLessonBadges.UserSpecific.PlaceNumber' | transloco}}
            {{enrollmentProperties.currentEnrollment!.placeNumber}}
        </span>

        <!-- General status badges -->
        <span class="badge badge-green"
            *ngIf="!enrollmentProperties.userIsEnrolled && enrollmentProperties.enrollmentIsOpen">
            {{'EventLessonBadges.General.EnrollmentOpen' | transloco}}
        </span>
        <span class="badge badge-grey"
            *ngIf="!enrollmentProperties.userIsEnrolled && enrollmentProperties.enrollmentIsExpired">
            {{'EventLessonBadges.General.EnrollmentClosed' | transloco}}
        </span>

        <ng-container *ngIf="enrollmentProperties.lotteryIsActive">
            <span class="badge badge-green" *ngIf="enrollmentProperties.lotteryEnrollmentIsOpen">
                {{'EventLessonBadges.General.LotteryOpen' | transloco}}
            </span>
            <span class="badge badge-grey" *ngIf="enrollmentProperties.lotteryEnrollmentIsExpired">
                {{'EventLessonBadges.General.LotteryClosed' | transloco}}
            </span>
        </ng-container>

        <span class="badge badge-grey" *ngIf="!enrollmentProperties.hasSeats">
            {{'EventLessonBadges.General.FullyBooked' | transloco}}
        </span>
        <span class="badge badge-red" *ngIf="enrollmentProperties.lessonIsCancelled">
            {{'EventLessonBadges.General.Cancelled' | transloco}}
        </span>
        <span class="badge badge-grey" *ngIf="enrollmentProperties.lessonIsOver">
            {{'EventLessonBadges.General.Past' | transloco}}
        </span>
    </ng-container>
</div>