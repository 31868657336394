<div class="container-fluid">
    <div class="row">
        <div class="col offset-md-2 col-md-8">
            <h1>{{'LessonEnrollments.Title' | transloco}}</h1>
            <div>
                <div class="pt-5" *ngIf="myLotteryEnrollments">
                    <app-my-lottery-enrollments-table *ngIf="myLotteryEnrollments"
                        [lotteryEnrollments]="myLotteryEnrollments!"
                        [title]="'LessonEnrollments.LotteryEnrollments' | transloco">
                    </app-my-lottery-enrollments-table>
                </div>
                <div class="pt-5" *ngIf="myEnrollments">
                    <app-my-enrollments-table *ngIf="myEnrollments" [enrollments]="myEnrollments!"
                        [title]="'LessonEnrollments.DefinitivEnrollments'  | transloco">
                    </app-my-enrollments-table>
                </div>
            </div>
            <div class="mb-5">
                <app-spinner *ngIf="!myEnrollments && ! myLotteryEnrollments" [margin]="'1rem auto'"></app-spinner>
            </div>
        </div>
    </div>
</div>