<div class="container-fluid">
    <div class="row">
        <div class="col offset-md-2 col-md-8">
            <h1>{{'Eventegistrations.Title' | transloco}}</h1>
            <div *ngIf="openRegistrations && completedRegistrations">
                <div class="pt-5">
                    <app-registrations-table *ngIf="openRegistrations"
                        [title]="'Eventegistrations.Temporary' | transloco" [registrations]="openRegistrations">
                    </app-registrations-table>
                </div>
                <div class="pt-5">
                    <app-registrations-table *ngIf="completedRegistrations"
                        [title]="'Eventegistrations.Definite' | transloco" [showToggle]="true"
                        [registrations]="completedRegistrations">
                    </app-registrations-table>
                </div>
            </div>
            <div class="mb-5">
                <app-spinner *ngIf="!openRegistrations || !completedRegistrations" [margin]="'1rem auto'"></app-spinner>
            </div>
        </div>
    </div>
</div>