<div class="container-fluid mt-3">
    <h1>{{'Staff.KnowHow.Title' | transloco }}</h1>
    <ul class="nav nav-tabs pointer">
        <li class="nav-item nav-pills-asvz" role="presentation">
            <a class="nav-link" routerLink="/staff/know-how/documents" routerLinkActive="active">
                {{'Staff.KnowHow.Documents' | transloco }}
            </a>
        </li>
        <li class="nav-item nav-pills-asvz" role="presentation">
            <a class="nav-link" routerLink="/staff/know-how/faqs" routerLinkActive="active">
                {{'Staff.KnowHow.Faqs' | transloco }}
            </a>
        </li>
    </ul>

    <div style="margin-top:1rem;">
        <router-outlet></router-outlet>
    </div>
</div>