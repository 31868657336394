import { Injectable } from '@angular/core';
import { Lesson } from '../models/lesson.model';
import * as moment from 'moment';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';
import { BehaviorSubject } from 'rxjs';
import { EnrollmentStateProperties } from '../models/enrollment-state-properties.model';
import { RequiredSkill } from 'src/app/events/required-skill.model';
import { Membership } from 'src/app/memberships/models';
import { MembershipState } from 'src/app/memberships/membership-state.enum';
import { CurrentEnrollment } from '../models/current-enrollment.model';
import { EnrollmentStatus } from '../enums/enrollment-status.enum';
import { LessonStatusEnum } from '../enums/lesson-status.enum';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { EnrollmentLotteryStateModel } from '../models/enrollment-lottery-state.model';
import { EnrollmentLotteryStepEnum } from '../enums/enrollment-lottery-step.enum';
import { isSameDay } from 'date-fns';

@Injectable()
export class EnrollmentStateService {

    private lesson!: Lesson;
    private memberships!: Membership[];
    private missingSkills!: RequiredSkill[];
    private currentEnrollment!: CurrentEnrollment | null;
    private currentEnrollmentLotteryState!: EnrollmentLotteryStateModel | null;

    private enrollmentStateProperties = new BehaviorSubject<EnrollmentStateProperties | null>(null);

    enrollmentStateProperties$ = this.enrollmentStateProperties.asObservable();

    constructor(private userAuthorizationService: UserAuthorizationService, private dateHelperService: DateHelperService) { }

    setData(lesson: Lesson, missingSkills: RequiredSkill[], memberships: Membership[], currentEnrollment: CurrentEnrollment | null, currentLotteryState: EnrollmentLotteryStateModel | null): void {
        this.lesson = lesson;
        this.missingSkills = missingSkills;
        this.memberships = memberships;
        this.currentEnrollment = currentEnrollment;
        this.currentEnrollmentLotteryState = currentLotteryState;
        this.setEnrollmentStateProperties();
    }

    setCurrentEnrollmentLotteryState(enrollmentLotteryStateNew: EnrollmentLotteryStateModel | null): void {
        this.currentEnrollmentLotteryState = enrollmentLotteryStateNew;
    }

    public userHasValidMembershipForLesson(): boolean {
        let hasValidMembershipForStartDate = false;

        this.memberships?.forEach(x => {
            if (x.status === MembershipState.Completed
                && moment(this.lesson.starts).startOf('day').isSameOrBefore(x.validUntil)
                && moment(this.lesson.starts).startOf('day').isSameOrAfter(x.validFrom)) {
                hasValidMembershipForStartDate = true;
            }
        });

        return hasValidMembershipForStartDate;
    }

    setEnrollmentStateProperties(): void {

        const properties: EnrollmentStateProperties = {
            hasSeats: this.hasSeats,
            withdrawIsExpired: this.withdrawIsExpired,
            lessonIsLivestream: this.lessonIsLivestream,

            lessonIsActive: this.lessonIsActive,
            lessonIsOver: this.lessonIsOver,
            lessonIsCancelled: this.lessonIsCanceled,

            translateParams: this.translateParams,
            userHasValidMembershipForLesson: this.userHasValidMembershipForLesson(),
            userCanEnroll: this.userCanEnroll(),
            userHasMissingSkill: this.userHasMissingSkill,

            // enrollment
            userIsEnrolled: this.userIsEnrolled,
            enrollmentIsOpen: this.enrollmentIsOpen,
            enrollmentIsExpired: this.enrollmentIsExpired,
            currentEnrollment: this.currentEnrollment,

            // lottery enrollment
            lotteryIsActive: this.lotteryIsActive,
            lotteryEnrollmentIsOpen: this.lotteryEnrollmentIsOpen,
            lotteryEnrollmentIsExpired: this.lotteryEnrollmentIsExpired,

            isLotteryDrawOnSameDay: isSameDay(new Date(), new Date(this.lesson.enrollmentFrom)),

            userIsEnrolledToLottery: this.userIsEnrolledToLottery,
            currentEnrollmentLotteryState: this.currentEnrollmentLotteryState,
            userCanEnrollToLottery: this.userCanEnrollToLottery()
        };

        this.enrollmentStateProperties.next(properties);
    }

    /**
* Sets the flag indicating whether the user is allowed to enroll in the lesson.
* The user must have valid memberships, meet skill requirements, and the lesson must be active,
* have available seats, and not expired.
*/
    public userCanEnroll(): boolean {

        const alreadyEnrolled = false;
        if (this.memberships || this.lesson.externeAnmeldung) {

            return this.missingSkills.length === 0 &&
                !this.userIsEnrolled &&
                this.lessonIsActive &&
                !this.enrollmentIsExpired &&
                this.hasSeats &&
                !alreadyEnrolled &&
                (this.userHasValidMembershipForLesson() || this.lesson.externeAnmeldung)
        }

        return false;
    }

    public userCanEnrollToLottery(): boolean {
        if (!this.userCanEnroll()) return false;
        if (this.userIsEnrolledToLottery) return false;
        if (!this.lotteryIsActive) return false;
        return true;
    }

    get userIsEnrolled(): boolean {
        return this.currentEnrollment?.status === EnrollmentStatus.Completed
    }

    get userHasMissingSkill(): boolean {
        return this.missingSkills.length !== 0
    }

    get hasSeats(): boolean {
        return (this.lesson.participantsMax - this.lesson.participantCount) > 0 || this.lesson.participantsMax === null;
    }

    get withdrawIsExpired(): boolean {
        return moment(this.lesson.cancelationUntil).isBefore(moment());
    }

    get enrollmentIsExpired(): boolean {
        return moment(this.lesson.enrollmentUntil).isBefore(moment());
    }

    get lessonIsLivestream(): boolean {
        return this.lesson.isLiveStream;
    }

    get lessonIsActive(): boolean {
        return this.lesson.status === LessonStatusEnum.Active || this.canPrepareLesson(this.lesson.status);
    }

    get lessonIsOver(): boolean {
        return moment(this.lesson.ends).isBefore(moment());
    }

    get lessonIsCanceled(): boolean {
        return this.lesson.status === LessonStatusEnum.Canceled
    }

    get enrollmentIsOpen(): boolean {
        const currentDate = new Date();
        currentDate.setMilliseconds(0);
        const enrollmentFromDate = new Date(this.lesson.enrollmentFrom);
        return this.dateHelperService.isAfter(currentDate, enrollmentFromDate) || this.dateHelperService.isEqual(currentDate, enrollmentFromDate);
    }

    get lotteryIsActive(): boolean {
        if (this.lesson.lotteryEnrollmentFrom == null) return false;
        return this.currentEnrollmentLotteryState?.lotteryStep != EnrollmentLotteryStepEnum.NoLottery;
    }

    get lotteryEnrollmentIsOpen(): boolean {
        if (!this.lotteryIsActive) return false;
        if (this.lesson.lotteryEnrollmentFrom == null) return false;
        if (this.lesson.lotteryEnrollmentTo == null) return false;

        return (moment(this.lesson.lotteryEnrollmentFrom).isBefore(moment()) && moment(this.lesson.lotteryEnrollmentTo).isAfter(moment()));
    }

    get lotteryEnrollmentIsExpired(): boolean {
        if (this.lesson.lotteryEnrollmentTo == null) return false;
        return moment(this.lesson.lotteryEnrollmentTo).isBefore(moment());
    }


    get userIsEnrolledToLottery(): boolean {
        return this.currentEnrollmentLotteryState?.isInLottery ?? false;
    }

    get translateParams(): any {
        return {
            'registrationFrom': moment(this.lesson.enrollmentFrom).format('DD.MM.YYYY HH:mm:ss'),
            'cancelationUntil': moment(this.lesson.cancelationUntil).format('DD.MM.YYYY HH:mm'),
            'lessonTitle': this.lesson.title
        };
    }

    private canPrepareLesson(status: number): boolean {
        const currentUser = this.userAuthorizationService.getUser();
        return status === LessonStatusEnum.InPreparation &&
            (
                currentUser.hasRoleAdmin() ||
                currentUser.hasRoleOffice() ||
                currentUser.hasRoleHspl()
            );
    }
}
