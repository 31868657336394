<!-- Action messages -->
<div class="row">
    <div class="col-sm-12">

        <!-- Normal enrollment -->
        <ng-container>
            <div class="alert alert-success" *ngIf="enrollmentMessageStateService.showEnrollSuccessMsg$ | async">
                {{'LessonEnrollmentMessages.EnrollSuccess' | transloco}}
            </div>
            <div class="alert alert-danger" *ngIf="enrollmentMessageStateService.showEnrollErrorMsg$ | async">
                {{'LessonEnrollmentMessages.EnrollError' | transloco}}
            </div>
            <div class="alert alert-primary" *ngIf="enrollmentMessageStateService.showWithdrawSuccessMsg$ | async">
                {{'LessonEnrollmentMessages.WithdrawSuccess' | transloco}}
            </div>
            <div class="alert alert-danger" *ngIf="enrollmentMessageStateService.showWithdrawErrorMsg$ | async">
                {{'LessonEnrollmentMessages.WithdrawError' | transloco}}
            </div>
        </ng-container>

        <!-- Lottery enrollment -->
        <ng-container>
            <div class="alert alert-success" *ngIf="enrollmentMessageStateService.showLotteryEnrollSuccessMsg$ | async">
                {{'LessonLotteryEnrollmentMessages.EnrollSuccess' | transloco}}
            </div>
            <div class="alert alert-danger" *ngIf="enrollmentMessageStateService.showLotteryEnrollErrorMsg$ | async">
                {{'LessonLotteryEnrollmentMessages.EnrollError' | transloco}}
            </div>
            <div class="alert alert-primary"
                *ngIf="enrollmentMessageStateService.showLotteryWithdrawSuccessMsg$ | async">
                {{'LessonLotteryEnrollmentMessages.WithdrawSuccess' | transloco}}
            </div>
            <div class="alert alert-danger" *ngIf="enrollmentMessageStateService.showLotteryWithdrawErrorMsg$ | async">
                {{'LessonLotteryEnrollmentMessages.WithdrawError' | transloco}}
            </div>
        </ng-container>

        <!-- Shared enrollment -->
        <div class="alert alert-danger" *ngIf="enrollmentMessageStateService.validationError$ | async">
            {{enrollmentMessageStateService.validationError$ | async}}
        </div>
    </div>
</div>
<!-- Action messages end -->