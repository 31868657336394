import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { KnowHowRoutingModule } from './know-how-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { StaffKnowHowDocumentsComponent } from './documents/documents.component';
import { StaffKnowHowFaqsComponent } from './faqs/faqs.component';
import { StaffKnowHowMainLayoutComponent } from './know-how-main-layout/know-how-main-layout.component';

@NgModule({
    imports: [
        NgSelectModule,
        FormsModule,
        CommonModule,
        SharedModule,
        KnowHowRoutingModule
    ],
    declarations: [
        StaffKnowHowMainLayoutComponent,
        StaffKnowHowDocumentsComponent,
        StaffKnowHowFaqsComponent,
    ],
    exports: [],
    providers: []
})
export class KnowHowModule {
    constructor(translate: TranslocoService) {
        translate.setDefaultLang('de');
    }
}
