import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffNewsComponent } from './news/news.component';
import { StaffStatisticsComponent } from './statistics/statistics.component';
import { StaffPlannerComponent } from './planner/planner.component';
import { KnowHowModule } from './know-how/know-how.module';
import { StaffAdminComponent } from './admin/staff-admin.component';

const routes: Routes = [
    {
        path: 'admin/impersonation',
        component: StaffAdminComponent
    },
    {
        path: 'admin',
        redirectTo: 'admin/impersonation',
        pathMatch: 'full'
    },
    {
        path: 'news',
        component: StaffNewsComponent
    },
    {
        path: 'know-how',
        loadChildren: () => import('./know-how/know-how.module').then(m => KnowHowModule),
    },
    {
        path: 'statistics',
        component: StaffStatisticsComponent
    },
    {
        path: 'planner',
        component: StaffPlannerComponent
    },
    {
        path: '',
        redirectTo: 'news',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class StaffRoutingModule { }  