<ng-container *ngIf="dataLoaded && enrollmentProperties">

    <app-enrollment-badges [enrollmentProperties$]="enrollmentProperties$"></app-enrollment-badges>

    <ng-container *ngIf="lesson.status !== lessonStatusEnum.Canceled && lesson.enrollmentEnabled">
        <div class="d-flex gap-2 align-top mt-3">
            <!-- Normal enrollment -->
            <ng-container *ngIf="
                userIsLoggedIn
                && (!enrollmentProperties.lotteryIsActive || enrollmentProperties.lotteryEnrollmentIsExpired)">
                <!-- User is allowed to enroll -->

                <app-lessons-enrollment-button *ngIf="enrollmentProperties.userCanEnroll"
                    [enrollmentProperties]="(enrollmentProperties$ | async)!" (buttonClicked)="enrollForLesson()">
                </app-lessons-enrollment-button>

                <!-- User is enrolled -->
                <app-lesson-withdraw-button
                    *ngIf="enrollmentProperties.userIsEnrolled && enrollmentProperties.currentEnrollment"
                    [enrollmentProperties]="enrollmentProperties" (buttonClicked)="withdrawFromLesson()">
                </app-lesson-withdraw-button>
            </ng-container>

            <!-- Lottery enrollment -->
            <ng-container *ngIf="userIsLoggedIn 
                && enrollmentProperties.lotteryIsActive 
                &&  !enrollmentProperties.lotteryEnrollmentIsExpired">

                <!-- Lottery is open -->
                <ng-container *ngIf="enrollmentProperties.lotteryEnrollmentIsOpen">
                    <!-- User is allowed to participate in lottery -->
                    <div
                        *ngIf="!enrollmentProperties.userIsEnrolledToLottery && enrollmentProperties.userCanEnrollToLottery">
                        <button class="btn btn-primary" (click)="enrollForLessonLottery()" id="btnRegister">
                            <i class="fa fa-user-plus color-white" aria-hidden="true"></i>
                            {{'LessonActions.EnrollForLessonLottery' | transloco | uppercase}}
                        </button>
                        <p class="m-0 mt-1" *ngIf="!enrollmentProperties.isLotteryDrawOnSameDay"
                            [innerHTML]="'LessonLotteryInformation.DrawDateTomorow' | transloco: {drawDate: lesson.enrollmentFrom | date : 'HH:mm'}">
                        </p>
                        <p class="m-0 mt-1" *ngIf="enrollmentProperties.isLotteryDrawOnSameDay"
                            [innerHTML]="'LessonLotteryInformation.DrawDateToday' | transloco: {drawDate: lesson.enrollmentFrom | date : 'HH:mm'}">
                        </p>
                    </div>

                    <!-- User is part of the lottery -->
                    <div
                        *ngIf="enrollmentProperties.userIsEnrolledToLottery && enrollmentProperties.lotteryEnrollmentIsOpen">
                        <button class="btn btn-primary" (click)="withdrawFromLessonLottery()" id="btnRegister">
                            <i class="fa fa-user-times color-white" aria-hidden="true"></i>
                            {{'LessonActions.WithdrawFromLessonLottery' | transloco | uppercase}}
                        </button>
                        <p class="m-0 mt-1" *ngIf="!enrollmentProperties.isLotteryDrawOnSameDay"
                            [innerHTML]="'LessonLotteryInformation.DrawDateTomorow' | transloco: {drawDate: lesson.enrollmentFrom | date : 'HH:mm'}">
                        </p>
                        <p class="m-0 mt-1" *ngIf="enrollmentProperties.isLotteryDrawOnSameDay"
                            [innerHTML]="'LessonLotteryInformation.DrawDateToday' | transloco: {drawDate: lesson.enrollmentFrom | date : 'HH:mm'}">
                        </p>
                    </div>

                </ng-container>

                <ng-container *ngIf="!enrollmentProperties.lotteryEnrollmentIsOpen">
                    <!-- User is allowed to participate in lottery but lottery is not open currently -->
                    <div>
                        <button class="btn btn-primary" id="btnRegister" [disabled]="true">
                            <i class="fa fa-user-plus color-white" aria-hidden="true"></i>
                            {{'LessonActions.EnrollForLessonLottery' | transloco | uppercase}}
                        </button>
                        <p class="m-0 mt-1"
                            [innerHTML]="'LessonLotteryInformation.OpenDate' | transloco: {openDate: lesson.lotteryEnrollmentFrom | date : 'EEEE, dd. MMMM', openTime: lesson.lotteryEnrollmentFrom | date : 'HH:mm'}">
                        </p>
                    </div>

                </ng-container>

            </ng-container>

            <!-- Livestream show button -->
            <app-show-live-stream-button
                *ngIf="this.lesson.isLiveStream && userIsLoggedIn && (enrollmentProperties.userHasValidMembershipForLesson || lesson.externeAnmeldung)"
                (buttonClicked)="showLiveStream()">
            </app-show-live-stream-button>

        </div>

        <div class="row" *ngIf="enrollmentProperties.userIsEnrolled">
            <div class="col-sm-12">
                <div>
                    <span *ngIf="enrollmentProperties.withdrawIsExpired">
                        {{'LessonMessages.WithdrawExpiredInfo' | transloco:enrollmentProperties.translateParams}}
                    </span>
                </div>
            </div>
        </div>

        <div
            *ngIf="userIsLoggedIn && (!enrollmentProperties.userHasValidMembershipForLesson && !lesson.externeAnmeldung)">
            <button class="btn btn-default" routerLink="/memberships">{{'GoToMemberships' | transloco }}</button>
        </div>

        <app-login-button *ngIf="!userIsLoggedIn"></app-login-button>

    </ng-container>

    <!-- General enrollment information -->
    <ng-container *ngIf="lesson.status !== lessonStatusEnum.Canceled && lesson.enrollmentEnabled">
        <div class="mt-3">
            <div class="alert alert-warning"
                *ngIf="enrollmentProperties.enrollmentIsExpired && !enrollmentProperties.userIsEnrolled">
                {{'LessonEnrollmentMessages.EnrollmentDateExpired' | transloco}}</div>
            <div class="alert alert-warning"
                *ngIf="!enrollmentProperties.hasSeats && !enrollmentProperties.userIsEnrolled">
                {{'LessonEnrollmentMessages.LessonFullyBooked' |
                transloco}}</div>
            <div class="alert alert-primary"
                *ngIf="!userIsLoggedIn && enrollmentProperties.hasSeats && !enrollmentProperties.enrollmentIsExpired">
                {{'LessonEnrollmentMessages.LoginForEnroll' | transloco}}</div>
            <div class="alert alert-danger"
                *ngIf="userIsLoggedIn && (!enrollmentProperties.userHasValidMembershipForLesson && !lesson.externeAnmeldung)">
                {{'LessonEnrollmentMessages.NoValidMembershipForLesson' | transloco}}</div>
        </div>
    </ng-container>

</ng-container>

<app-enrollment-messages *ngIf="dataLoaded"></app-enrollment-messages>

<app-show-live-stream-button (buttonClicked)="showLiveStream()"
    *ngIf="lesson.isLiveStream && userIsLoggedIn && (enrollmentProperties?.userHasValidMembershipForLesson) && !lesson.enrollmentEnabled && lesson.status !== lessonStatusEnum.Canceled">
</app-show-live-stream-button>