import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MembershipState } from 'src/app/memberships/membership-state.enum';
import { Membership } from 'src/app/memberships/models';

@Injectable()
export class MembershipHelperService {
  constructor() { }


  public userHasValidMembershipForLesson(memberships: Membership[], lessonStartDate: Date): boolean {
    let hasValidMembershipForStartDate = false;

    memberships.forEach(x => {
      if (x.status === MembershipState.Completed
        && moment(lessonStartDate).startOf('day').isSameOrBefore(x.validUntil)
        && moment(lessonStartDate).startOf('day').isSameOrAfter(x.validFrom)) {
        hasValidMembershipForStartDate = true;
      }
    });

    return hasValidMembershipForStartDate;
  }

}