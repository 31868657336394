import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './know-how-main-layout.component.html',
  styleUrls: ['./know-how-main-layout.component.scss']
})
export class StaffKnowHowMainLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
