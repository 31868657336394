import { Component, OnInit, Input } from '@angular/core';
import { EnrollmentLotteryList } from 'src/app/lessons/models/enrollment-lottery-list.model';

@Component({
    selector: 'app-my-lottery-enrollments-table',
    templateUrl: './my-lottery-enrollments-table.component.html',
    styleUrls: ['./my-lottery-enrollments-table.component.scss']
})
export class MyLotteryEnrollmentsTableComponent implements OnInit {

    @Input() title!: any;
    @Input() lotteryEnrollments!: EnrollmentLotteryList[];

    constructor() {
    }

    ngOnInit() {
    }

}

