<button *ngIf="!isLoaded" class="btn btn-default" disabled>
  <app-spinner [size]="'30px'" [margin]="'0'"></app-spinner>
</button>

<!-- Registration State Messages -->
<ng-container *ngIf="isLoaded">
  <ng-container *ngIf="event.registrationEnabled">
    <div class="alert alert-danger" *ngIf="showRegisterFailMsg"
      [innerHTML]="'EventMessages.RegisterErrorMsg' | transloco"></div>
    <div class="alert alert-danger" *ngIf="showValidationError">{{validationError}}</div>
    <div class="alert alert-primary" *ngIf="showRegistrationCancelledMsg">{{'EventMessages.RegistrationCancelledMsg' |
      transloco}}</div>
    <div class="alert alert-primary" *ngIf="showRegistrationErrorMsg"
      [innerHTML]="'EventMessages.RegistrationErrorMsg' | transloco"></div>
    <div class="alert alert-primary" *ngIf="needsAdditionalPayment">{{'EventMessages.RegistrationNeedsAdditionalPayment'
      | transloco}}</div>
    <div class="alert alert-primary" *ngIf="alreadyRegistered">
      {{'EventMessages.AlreadyRegisteredMsg' | transloco}}
      <a [routerLink]="['/registration', registrationKeys!.id, 'details']">
        {{'EventMessages.AlreadyRegisteredLinkText' | transloco}}
      </a>
    </div>
    <div class="alert alert-danger" *ngIf="event.status === eventState.Canceled">
      {{'EventMessages.EventCancelledMsg' |transloco }}
    </div>
    <div class="alert alert-primary" *ngIf="isOnWaitlist" [innerHTML]="'StateMessages.State_1.Msg' | transloco"></div>
    <div class="alert alert-primary" *ngIf="registrationUntilDateIsExpired && !alreadyRegistered">
      {{'EventMessages.RegistrationDateExpired' | transloco}}</div>
    <div class="alert alert-danger"
      *ngIf="userIsLoggedIn && registrationIsOpen && !eventHasUserPriceClass && !priceOnRequest && userHasValidMembership">
      {{'EventMessages.NoMatchingPriceCategory' | transloco}}</div>
    <div class="alert alert-danger" *ngIf="userIsLoggedIn && !userHasValidMembership">
      {{'EventMessages.NoValidMembership' | transloco}}</div>
  </ng-container>
  <!-- End of Messages -->

  <!-- if the user can enroll normally, it will also display the label according to the state of the registration -->
  <div *ngIf="canRegister && !alreadyRegistered && !needsAdditionalPayment">

    <ng-container *ngIf="registrationIsOpen">

      <div class="emulated-flex-gap" *ngIf="hasSeats || continueRegistration">
        <!-- seats available -->
        <div>
          <button class="btn btn-default" (click)="register(event.id)" id="btnRegister" [disabled]="registering">
            <span class="fa fa-book"></span>
            <span *ngIf="continueRegistration"> {{'EventActions.ContinueRegistration' | transloco}}</span>
            <span *ngIf="!continueRegistration"> {{'EventActions.Register' | transloco}}</span>
          </button>
        </div>
        <div *ngIf="this.event.isLiveStream && userIsLoggedIn && userHasValidMembership" style="margin-bottom:2em;">
          <button class="btn btn-default btn-livestream mx-5" id="btnLiveStream" (click)="showLiveStream()">
            <i class="fa fa-play color-white" aria-hidden="true"></i>&nbsp; {{'LiveStream.StartLiveStream' | transloco |
            uppercase}}
          </button>
        </div>
      </div>

      <ng-container *ngIf="!hasSeats && !isOnWaitlist && !continueRegistration">
        <!-- no seats available and is not on waitlist -->
        <div class="alert alert-primary">
          {{'EventMessages.EventFullyBookedMsg' | transloco}}
        </div>

        <div class="emulated-flex-gap">
          <div>
            <button class="btn btn-default" id="btnRegisterWaitList" (click)="registerForWaitlist(event.id)"
              [disabled]="registering">
              {{'EventMessages.PutOnWaitlist' | transloco }}
            </button>
          </div>
          <div *ngIf="this.event.isLiveStream && userIsLoggedIn && userHasValidMembership" style="margin-bottom:2em;">
            <button class="btn btn-default btn-livestream mx-5" id="btnLiveStream" (click)="showLiveStream()">
              <i class="fa fa-play color-white" aria-hidden="true"></i>&nbsp; {{'LiveStream.StartLiveStream' | transloco
              | uppercase}}
            </button>
          </div>
        </div>
      </ng-container>

      <div class="emulated-flex-gap" *ngIf="isOnWaitlist">
        <!-- is on waitlist-->
        <div>
          <button class="btn btn-default" id="btnDeleteWaitList"
            (click)="deleteWaitlistRegistration(registrationKeys!.id!)" [disabled]="registering">
            {{'EventMessages.DeleteWaitlistRegistration' | transloco}}
          </button>
        </div>
        <div *ngIf="!this.hasSeats && this.event.isLiveStream && userIsLoggedIn && userHasValidMembership"
          style="margin-bottom:2em;">
          <button class="btn btn-default btn-livestream mx-5" id="btnLiveStream" (click)="showLiveStream()">
            <i class="fa fa-play color-white" aria-hidden="true"></i>&nbsp; {{'LiveStream.StartLiveStream' | transloco |
            uppercase}}
          </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!registrationFromDateIsOpen && !registrationUntilDateIsExpired">
      <!-- can register, but timeslot is not open-->
      <div class="emulated-flex-gap">
        <div>
          <button class="btn-default btn disabled">
            <span class="fa fa-book"></span>
            {{'EventActions.Register' | transloco}}
          </button>
        </div>
        <div *ngIf="this.event.isLiveStream && userIsLoggedIn && userHasValidMembership" style="margin-bottom:2em;">
          <button class="btn btn-default btn-livestream mx-5" id="btnLiveStream" (click)="showLiveStream()">
            <i class="fa fa-play color-white" aria-hidden="true"></i>&nbsp; {{'LiveStream.StartLiveStream' | transloco |
            uppercase}}
          </button>
        </div>
      </div>
      <span style="margin-left:0.5em;">{{'EventMessages.RegistrationNotOpenMsg' | transloco }}</span>
    </div>

  </div>

  <!-- Cannot register -->
  <div class="d-inline-flex align-top"
    *ngIf="!canRegister && this.event.isLiveStream && userIsLoggedIn && userHasValidMembership">
    <button class="btn btn-default btn-livestream mx-5" id="btnLiveStream" (click)="showLiveStream()">
      <i class="fa fa-play color-white" aria-hidden="true"></i>&nbsp; {{'LiveStream.StartLiveStream' | transloco |
      uppercase}}
    </button>
  </div>

  <div *ngIf="userIsLoggedIn && !userHasValidMembership">
    <button class="btn btn-default" routerLink="/memberships">{{'GoToMemberships' | transloco }}</button>
  </div>
  <div *ngIf="!canRegister && isOnWaitlist">
    <button class="btn btn-default" (click)="deleteWaitlistRegistration(registrationKeys!.id!)" id="btnDeleteWaitList">
      {{'EventMessages.DeleteWaitlistRegistration' | transloco}}
    </button>
  </div>

  <div *ngIf="!userIsLoggedIn && !registrationUntilDateIsExpired && event.registrationEnabled">
    <div class="alert alert-primary">{{'EventMessages.LoginForRegistrationMsg' | transloco}}</div>
    <a [routerLink]="" (click)="login()" title="{{'LoginAndRegister' | transloco}}" class="btn btn-default">
      <i class="fa fa-sign-in"></i>
      {{'LoginAndRegister' | transloco }}
    </a>
  </div>
</ng-container>