import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AvailableImpersonationTrainerModel } from '../models/available-impersonation-trainer.model';

@Injectable({
    providedIn: 'root'
})
export class StaffAdminService {

    constructor(private httpService: HttpClient) { }

    public getAvailableImpersonationTrainers(): Observable<AvailableImpersonationTrainerModel[]> {
        return this.httpService.get<AvailableImpersonationTrainerModel[]>(`${environment.apiStaff}/Admin/Impersonation/AvailableTrainers`);
    }

}
