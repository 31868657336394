import { Component, OnInit, ViewChild } from '@angular/core';
import { StaffAdminService } from './services/staff-admin.service';
import { AvailableImpersonationTrainerModel } from './models/available-impersonation-trainer.model';
import { AgGridAngular } from 'ag-grid-angular';
import { TranslocoService } from '@ngneat/transloco';
import { GridReadyEvent } from 'ag-grid-community';
import { StaffAdminOptionsAgGridComponent } from './admin-options-ag-grid/admin-options-ag-grid.component';
import { GridService } from 'src/app/shared/ag-grid/grid.service';

@Component({
    templateUrl: './staff-admin.component.html',
    styleUrls: ['./staff-admin.component.scss'],
    providers: [GridService]
})
export class StaffAdminComponent implements OnInit {

    @ViewChild('agGrid') agGrid!: AgGridAngular;
    rowData: any = [];
    columnDefs: any = [];
    defaultColDef = {
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }

    availableImpersonationTrainers: AvailableImpersonationTrainerModel[] | null = null;

    constructor(
        private translocoService: TranslocoService,
        private gridService: GridService,
        private staffAdminService: StaffAdminService,
    ) { }

    ngOnInit(): void {
        this.LoadAvailableImpersonationTrainers();
    }

    private LoadAvailableImpersonationTrainers() {
        this.staffAdminService.getAvailableImpersonationTrainers().subscribe({
            next: (x) => {
                this.availableImpersonationTrainers = x;
                this.initGrid();
                this.gridService.processGrid(this.agGrid, this.availableImpersonationTrainers, this.constructor.name);
            },
            error: (x) => { }
        })
    }

    private initGrid() {
        this.columnDefs = [
            {
                field: 'actions',
                headerName: '',
                filter: false,
                sortable: false,
                cellRenderer: StaffAdminOptionsAgGridComponent,
                cellEditorPopupPosition: 'under',
                tooltipField: "edit",
                minWidth: 71,
                maxWidth: 71,
                pinned: 'left',
                type: 'client',
            },
            { field: 'firstName', headerName: this.translocoService.translate('Staff.Admin.FirstName'), sort: "asc", flex: 1 },
            { field: 'lastName', headerName: this.translocoService.translate('Staff.Admin.LastName'), sort: "asc", flex: 1 },
            { field: 'spacer', headerName: '', filter: false, sortable: false, flex: 1 },
        ];
    }
}
