import { Component, Input, OnInit } from '@angular/core';
import { UserAuthorizationService } from 'src/app/security/user-authorization.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { EnrollmentStateProperties } from '../../models/enrollment-state-properties.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-enrollment-badges',
    templateUrl: './enrollment-badges.component.html',
    styleUrls: ['./enrollment-badges.component.scss'],
})
export class EnrollmentBadgeComponent extends BaseComponent implements OnInit {

    @Input() enrollmentProperties$!: Observable<EnrollmentStateProperties | null>;

    enrollmentProperties: EnrollmentStateProperties | null = null;

    get userIsLoggedIn(): boolean {
        return this.authenticationService.getUser().isAuthenticated();
    }

    constructor(
        private authenticationService: UserAuthorizationService,
    ) {
        super();
    }
    ngOnInit(): void {
        this.whileImAlive(this.enrollmentProperties$).subscribe((properties) => {
            this.enrollmentProperties = properties;
        });
    }
}
