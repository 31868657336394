import { Component, OnInit, Input } from '@angular/core';
import { EnrollmentList } from 'src/app/lessons/models/enrollment-list.model';

@Component({
    selector: 'app-my-enrollments-table',
    templateUrl: './my-enrollments-table.component.html',
    styleUrls: ['./my-enrollments-table.component.scss']
})
export class MyEnrollmentsTableComponent implements OnInit {

    @Input() title!: any;
    @Input() enrollments!: EnrollmentList[];

    constructor() {
    }

    ngOnInit() {
    }

}

