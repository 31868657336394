<div class="p-3">
    <div class="d-flex gap-3 align-items-center mb-3">
        <div>
            <h1 class="m-0 p-0">{{'Staff.Admin.Impersonation' | transloco}}</h1>
        </div>
    </div>

    <div [ngClass]="{'d-none': availableImpersonationTrainers == undefined }">
        <ag-grid-angular #agGrid style="width: 100%; height: calc(100vh - 155px);" class="ag-theme-alpine"
            [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [tooltipShowDelay]="0"
            [enableBrowserTooltips]="true" [enableCellTextSelection]="true">
        </ag-grid-angular>
    </div>

    <app-spinner *ngIf="availableImpersonationTrainers == undefined"  margin="100px auto"></app-spinner>
</div>