import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentMessageStateService {
  //  Normal enrollment 
  private showEnrollSuccessMsgSubject = new BehaviorSubject<boolean>(false);
  private showEnrollErrorMsgSubject = new BehaviorSubject<boolean>(false);
  private showWithdrawSuccessMsgSubject = new BehaviorSubject<boolean>(false);
  private showWithdrawErrorMsgSubject = new BehaviorSubject<boolean>(false);

  //  Lottery enrollment
  private showLotteryEnrollSuccessMsgSubject = new BehaviorSubject<boolean>(false);
  private showLotteryEnrollErrorMsgSubject = new BehaviorSubject<boolean>(false);
  private showLotteryWithdrawSuccessMsgSubject = new BehaviorSubject<boolean>(false);
  private showLotteryWithdrawErrorMsgSubject = new BehaviorSubject<boolean>(false);

  //  Shared enrollment 
  private validationErrorSubject = new BehaviorSubject<string | null>(null);

  // Observables for components to subscribe
  //  Normal enrollment 
  public showEnrollSuccessMsg$ = this.showEnrollSuccessMsgSubject.asObservable();
  public showEnrollErrorMsg$ = this.showEnrollErrorMsgSubject.asObservable();
  public showWithdrawSuccessMsg$ = this.showWithdrawSuccessMsgSubject.asObservable();
  public showWithdrawErrorMsg$ = this.showWithdrawErrorMsgSubject.asObservable();

  //  Lottery enrollment 
  public showLotteryEnrollSuccessMsg$ = this.showLotteryEnrollSuccessMsgSubject.asObservable();
  public showLotteryEnrollErrorMsg$ = this.showLotteryEnrollErrorMsgSubject.asObservable();
  public showLotteryWithdrawSuccessMsg$ = this.showLotteryWithdrawSuccessMsgSubject.asObservable();
  public showLotteryWithdrawErrorMsg$ = this.showLotteryWithdrawErrorMsgSubject.asObservable();

  //  Shared enrollment 
  validationError$ = this.validationErrorSubject.asObservable();

  // Methods to update the state
  //  Normal enrollment 
  setShowEnrollSuccessMsg(value: boolean) {
    this.showEnrollSuccessMsgSubject.next(value);
  }

  setShowEnrollErrorMsg(value: boolean) {
    this.showEnrollErrorMsgSubject.next(value);
  }

  setShowWithdrawSuccessMsg(value: boolean) {
    this.showWithdrawSuccessMsgSubject.next(value);
  }

  setShowWithdrawErrorMsg(value: boolean) {
    this.showWithdrawErrorMsgSubject.next(value);
  }


  //  Lottery enrollment 

  setShowLotteryEnrollSuccessMsg(value: boolean) {
    this.showLotteryEnrollSuccessMsgSubject.next(value);
  }

  setShowLotteryEnrollErrorMsg(value: boolean) {
    this.showLotteryEnrollErrorMsgSubject.next(value);
  }

  setShowLotteryWithdrawSuccessMsg(value: boolean) {
    this.showLotteryWithdrawSuccessMsgSubject.next(value);
  }

  setShowLotteryWithdrawErrorMsg(value: boolean) {
    this.showLotteryWithdrawErrorMsgSubject.next(value);
  }

  //  Shared enrollment 
  setValidationError(message: string) {
    this.validationErrorSubject.next(message);
  }



  resetMessages() {
    //  Normal enrollment 
    this.showEnrollSuccessMsgSubject.next(false);
    this.showEnrollErrorMsgSubject.next(false);
    this.showWithdrawSuccessMsgSubject.next(false);
    this.showWithdrawErrorMsgSubject.next(false);

    //  Lottery enrollment 
    this.showLotteryEnrollSuccessMsgSubject.next(false);
    this.showLotteryEnrollErrorMsgSubject.next(false);
    this.showLotteryWithdrawSuccessMsgSubject.next(false);
    this.showLotteryWithdrawErrorMsgSubject.next(false);

    //  Shared enrollment 
    this.validationErrorSubject.next(null);
  }

}