import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaOverviewComponent } from './ma-overview/ma-overview.component';
import { MaDetailsComponent } from './ma-details/ma-details.component';
import { MaListComponent } from './ma-list/ma-list.component';
import { MaFilterListComponent } from './ma-filter-list/ma-filter-list.component';

const routes: Routes = [
  {
    path: '', component: MaOverviewComponent, children: [
      {
        path: 'completed', component: MaFilterListComponent,
        data: { type: 'completed' }
      },
      {
        path: 'all', component: MaFilterListComponent,
        data: { type: 'all' }
      },
      {
        path: 'archived', component: MaFilterListComponent,
        data: { type: 'archived' }
      },
      {
        path: 'current/:showDocumentsInPerson', component: MaListComponent
      },
      {
        path: '',
        redirectTo: 'current/online',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: ':membershipApplicationId', component: MaDetailsComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MembershipApplicationsRoutingModule { }
