import { Component, OnInit } from '@angular/core';
import { EnrollmentMessageStateService } from '../../services/enrollment-message-state.service';

@Component({
  selector: 'app-enrollment-messages',
  templateUrl: './enrollment-messages.component.html',
  styleUrls: ['./enrollment-messages.component.scss']
})
export class EnrollmentMessagesComponent {

  constructor(protected enrollmentMessageStateService: EnrollmentMessageStateService) { }
}
