import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { StaffRoutingModule } from './staff-routing.module';
import { StaffNewsComponent } from './news/news.component';
import { StaffPlannerComponent } from './planner/planner.component';
import { StaffStatisticsComponent } from './statistics/statistics.component';
import { StaffMainLayoutComponent } from './staff-main-layout/staff-main-layout.component';
import { StaffAdminComponent } from './admin/staff-admin.component';
import { AgGridModule } from 'ag-grid-angular';
import { StaffAdminOptionsAgGridComponent } from './admin/admin-options-ag-grid/admin-options-ag-grid.component';
@NgModule({
    imports: [
        NgSelectModule,
        FormsModule,
        CommonModule,
        SharedModule,
        StaffRoutingModule,
        AgGridModule
    ],
    declarations: [
        StaffAdminComponent,
        StaffMainLayoutComponent,
        StaffNewsComponent,
        StaffPlannerComponent,
        StaffStatisticsComponent,
        StaffAdminOptionsAgGridComponent
    ],
    exports: [],
    providers: []
})
export class StaffModule {
    constructor(translate: TranslocoService) {
        translate.setDefaultLang('de');
    }
}
