import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffKnowHowFaqsComponent } from './faqs/faqs.component';
import { StaffKnowHowDocumentsComponent } from './documents/documents.component';
import { StaffKnowHowMainLayoutComponent } from './know-how-main-layout/know-how-main-layout.component';

const routes: Routes = [
    {
        path: '', component: StaffKnowHowMainLayoutComponent, children: [

            {
                path: 'documents', component: StaffKnowHowDocumentsComponent,
            },
            {
                path: 'faqs', component: StaffKnowHowFaqsComponent,
            },
            {
                path: '',
                redirectTo: 'documents',
                pathMatch: 'full'
            },
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})
export class KnowHowRoutingModule { }  