import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './admin-options-ag-grid.component.html',
    styleUrls: []
})
export class StaffAdminOptionsAgGridComponent implements ICellRendererAngularComp {

    isImpersonationAllowed!: boolean;
    personId!: number;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.personId = params.data.id;
    }

    openStartImpersonationModal() {
        window.location.href = environment.staffImpersonationStartUrl + this.personId;
    }

    refresh() { return false; }
}
