
import { forkJoin as observableForkJoin, Subject, Observable } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { AccountService } from '../../../account/account.service';
import { Account } from '../../../account/account.model';
import { AccountPut } from '../../../account/account-put.model';
import { CountryService } from '../../../shared/services/country.service';
import { Country } from '../../../shared/models/country.model';
import { RegistrationService } from '../registration.service';
import { RegistrationSummary } from '../registration-summary.model';
import { RegistrationGuardService } from '../registration-guard.service';
import { BaseComponent } from '@premotec/ngx-essentials';
import { AccountChangeDataFormComponent } from '../../../shared/account-change-data-form/account-change-data-form.component';
import { RegistrationStatusChange } from '../RegistrationStatusChange';
import { RegistrationStates } from '../registration-states.enum';
import { RegistrationStateService } from '../services/registration-state.service';

@Component({
    selector: 'app-registration-check-user-data',
    templateUrl: './registration-check-user-data.component.html',
    styleUrls: ['./registration-check-user-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RegistrationCheckUserDataComponent extends BaseComponent implements OnInit, OnDestroy {

    @ViewChild('accountChangeDataFromComponent') accountChangeDataFromComponent!: AccountChangeDataFormComponent;

    @Input() registrationSummary!: RegistrationSummary
    @Output() nextWizardState: EventEmitter<number> = new EventEmitter();
    @Output() dirtyState: EventEmitter<boolean> = new EventEmitter();

    disableSendButton = false;
    @Input() disableBackButton = false;
    isLoading = true;
    userData!: AccountPut;
    confirmForm!: FormGroup;

    submitted = false;
    backSubmitted = false;
    httpError = false;

    constructor(
        private accountService: AccountService,
        private registrationService: RegistrationService,
        private registrationGuardService: RegistrationGuardService,
        private fb: FormBuilder,
        private registrationStateService: RegistrationStateService) {
        super();
    }

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.confirmForm = this.fb.group({
            acceptAvb: [false, Validators.requiredTrue],
            acceptAusschreibung: [false, Validators.requiredTrue],
            acceptMembership: [false, Validators.requiredTrue],
            acceptCorrectContactDetails: [false, Validators.requiredTrue]
        });

        this.isLoading = false;

        this.confirmForm.statusChanges.subscribe(() => {
            this.dirtyState.emit(this.confirmForm.dirty);
        });

    }

    sendForm() {
        this.httpError = false;

        // needed for picture validation => check current value
        this.accountChangeDataFromComponent.updateValueAndValidityPictureControl();

        if (this.accountChangeDataFromComponent.getForm().valid && this.confirmForm.valid) {
            this.disableSendButton = true;
            this.registrationStateService.stopStatusTimer();
            observableForkJoin(
                this.accountService.updateAccountDetails(this.accountChangeDataFromComponent.getUpdatedUserData()),
                this.registrationService.confirmRegistration(this.registrationSummary.id!, this.confirmForm.value)
            )
                .subscribe(
                    data => {
                        this.nextWizardState.emit(data[1].status);
                    },
                    (error) => {
                        this.httpError = true;
                        this.disableSendButton = false;
                        this.registrationStateService.startStatusChangedTimer(this.registrationSummary.id!, this.registrationSummary.status!);
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                    }
                );
        } else {
            this.submitted = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;

            if (!this.confirmForm.valid) {
                console.log('Confirmation Form is invalid, please select all conditions');
            }

            if (!this.accountChangeDataFromComponent.getForm().valid) {
                console.log('Userdata From is invalid, please fill in all required fields');
            }
        }
    }

    back() {
        this.registrationGuardService.canDeactivate().subscribe(canDeactivate => {
            if (canDeactivate) {
                this.nextWizardState.emit(RegistrationStates.New);
            }
        });
    }

    enablePassPhotoUpload() {
        if (this.accountChangeDataFromComponent !== undefined && this.accountChangeDataFromComponent !== null) {
            if (this.accountChangeDataFromComponent.account != undefined && this.accountChangeDataFromComponent.account != null) {
                return !this.accountChangeDataFromComponent.account.hasPicture;
            }
        }
        return false;
    }
}
