import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { EnrollmentList } from '../../models/enrollment-list.model';
import { EnrollmentsService } from '../../services/enrollments.service';
import { EnrollmentLotteryList } from '../../models/enrollment-lottery-list.model';

@Component({
    selector: 'app-my-lessons-overview',
    templateUrl: './my-lessons-overview.component.html',
    styleUrls: ['./my-lessons-overview.component.scss']
})
export class MyLessonsOverviewComponent implements OnInit {
    myEnrollments: EnrollmentList[] | null = null;
    myLotteryEnrollments: EnrollmentLotteryList[] | null = null;

    dateToday: Date = new Date();

    constructor(private enrollmentsService: EnrollmentsService,
        private titleService: Title,
        private translocoService: TranslocoService) { }

    ngOnInit() {
        // set window title
        this.translocoService.selectTranslate('LessonEnrollments.Title').subscribe(x => {
            this.titleService.setTitle(x);
        });

        this.enrollmentsService.getAllEnrollments().subscribe((enrollments: EnrollmentList[]) => {
            this.myEnrollments = enrollments.filter(enrollment => {
                return enrollment.status === 4 && moment(enrollment.lessonEnd).isSameOrAfter(moment(), 'day')
            });
        });

        this.enrollmentsService.getAllLotteryEnrollments().subscribe((lotteryEnrollments: EnrollmentLotteryList[] | null) => {
            if (lotteryEnrollments == null) this.myLotteryEnrollments = null;
            this.myLotteryEnrollments = lotteryEnrollments!.sort((a, b) => new Date(a.lessonEnrollmentFrom).getTime() - new Date(b.lessonEnrollmentFrom).getTime());
        });
    }
}

